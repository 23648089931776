import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";

function PackagesStatisticsList(props) {
    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );

    const [showFilters, setShowFilters] = useState(true);

    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listOfRecord, setListOfRecord] = useState([]);
    const [Category, setCategory] = useState("");
    const [MembershipName, setMembershipName] = useState("");
    const [AgreementStatus, setAgreementStatus] = useState("");

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();

    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            Category: Category,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
            AgreementStatus: AgreementStatus,
            PackageName: MembershipName,
        };

        //console.log(data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetPackagesList",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        // console.log(data);

        axios(api_config)
            .then(function (response) {
                if (response.data.status_code === 1) {
                    // console.log("data", response.data);
                    setListOfRecord(response.data.ListofRecords);
                    setLoading(false);
                    setTotalRecords(response.data.totalRecords);
                    setGrandTotal(response.data);
                }
                else if (response.data.status_code === 0 && response.data.status_message === "Invalid Access Key." || response.data.status_code === 2) {

                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                }
                else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }

            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, Category, StartDate, EndDate, MembershipName, AgreementStatus]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const renderLedger = () => {
        if (listOfRecord != null && listOfRecord.length > 0) {
            let count = 1;
            return listOfRecord.map((item, index) => {
                //console.log(item);
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Created_Date).format("DD/MM/YYYY HH:mm")}</td>
                        <td>{item.User_Name}</td>
                        <td>{item.SecretID}</td>
                        <td>{item.Name}</td>
                        <td>PKR {NumberFormat(item.Amount)}</td>
                        <td className="text-center">{item.FreeMember === "Yes" ? <span style={{ fontSize: "20px" }} className="material-symbols-outlined tick yes m-0 black-tick">done</span> : ""}</td>
                        <td>{item.AgreementClaim}</td>
                        <td>{item.AgreementStatus}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };

    return (
        <>
            <AdminRightsCheck />
            <div className="admin-content">

                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Total Packages
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <Link to={`/admin/total-packages-dashboard`} className="button button-outline-bs">
                                Back
                            </Link>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row p-3 gy-3 align-items-end">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Search by Category</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Category}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                >
                                                    <option value="" selected>
                                                        All
                                                    </option>
                                                    {/* <option value="Active">Active Membership</option>
                                                    <option value="Expired">Expire Membership</option> */}
                                                    <option value="Free">Free Packages</option>
                                                    <option value="Paid">Paid Packages</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Package Name</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={MembershipName}
                                                    onChange={(e) => setMembershipName(e.target.value)}
                                                >
                                                    <option value="" selected>
                                                        All
                                                    </option>

                                                    <option value="5">Starter</option>
                                                    <option value="10">Basic</option>
                                                    <option value="6">Standard</option>
                                                    <option value="7">Superior</option>
                                                    <option value="1">Gold</option>
                                                    <option value="2">Diamond</option>
                                                    <option value="3">Platinum</option>
                                                    <option value="4">Titanium</option>
                                                    <option value="8">Freedom</option>
                                                    <option value="9">Leader</option>
                                                    <option value="11">Superior1</option>
                                                    <option value="12">Premium</option>
                                                    <option value="13">Elite</option>
                                                    <option value="14">Royal</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Agreement Status</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={AgreementStatus}
                                                    onChange={(e) => setAgreementStatus(e.target.value)}
                                                >
                                                    <option value="" selected>
                                                        All
                                                    </option>

                                                    <option value="Not Claimed">Not Claimed</option>
                                                    <option value="Fee Received">Fee Received</option>
                                                    <option value="Claimed">Claimed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/total-packages-dashboard/Packages-Statistics-list/pdf/?StartDate=${StartDate}&EndDate=${EndDate}&Category=${Category}&Full_Name=${Full_Name}&AgreementStatus=${AgreementStatus}&MembershipName=${MembershipName}`}>PDF</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Purchased Date</th>
                                    <th>Username</th>
                                    <th>Package ID</th>
                                    <th>Package Name</th>
                                    <th>Package Amount</th>
                                    <th>Free Member</th>
                                    <th>Agreement Claim</th>
                                    <th>Agreement Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="11">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}

                                        {listOfRecord != null && listOfRecord.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th className="text-end border-bottom-0 pt-3" colSpan={5}>Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfRecord, 'Amount'))}</th>
                                                    <td className="border-bottom-0 pt-3"></td>
                                                </tr>
                                                <tr className="" style={{ borderButtom: "1px solid !important" }}>
                                                    <th className="text-end border-bottom-0 pt-3" colSpan={5}>Grand Total: </th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(GrandTotal.totalMembership)}</th>
                                                    <td className="border-bottom-0 pt-3"></td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}

export default PackagesStatisticsList;