import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import Form from 'react-bootstrap/Form';
import ReactTooltip from "react-tooltip";
import moment from "moment";
import AllPackagesDropDown from "../../Common/Dropdowns/AllPackagesDropDown";

function ViewTeam(props) {
    const userstatus = useParams();


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [ID, setID] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Userloading, setUserloading] = useState(false);
    const [TargetLoading, setTargetLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    //   console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();


    const [showFilters, setShowFilters] = useState(true);

    const [User, setUser] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    const [NoOfLevel, setNoOfLevel] = useState('');
    const [Designation, setDesignation] = useState('');
    const [RankName, setRankName] = useState('');
    const [MembershipName, setMembershipName] = useState("");



    useEffect(() => {
        document.title = "Mag Holdings Admin";
        const urlParams = new URLSearchParams(window.location.search);
        setUser(urlParams.get('Full_Name'));


        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: StartDate,
            endDate: EndDate,
            LevelNo: NoOfLevel,
            MemberType: MemberType,
            ID: userstatus.id,
            Postal_Address: Designation,
            RankName: RankName,
            PackageID: MembershipName,
            UserName: Full_Name,
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/ViewNetworkPage",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data, 'response');
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofMembers);
                    setLoading(false);
                    setGrandTotal(response.data);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(totalRecords);
                } else if (
                    (response.data.status_message === "Invalid Access Key.")) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate('/admin/login');
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, StartDate, EndDate, TargetLoading, Designation, RankName, MembershipName, userstatus, Full_Name, Userloading]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{moment(item.Created_Date).format("DD/MM/YYYY")}</td>
                        <td>{moment(item.BuyingDate).format("DD/MM/YYYY")}</td>
                        <td>{item.Full_Name}</td>
                        <td><Link to={`/admin/userstatistics-dashboard/leadership-view-team/${item.Nodes_IN_PK_Code}?Full_Name=${item.Full_Name}`}>{item.User_Name}</Link></td>
                        <td>{item.Name}</td>
                        <td>{item.Email_Address}</td>
                        <td>{item.Postal_Address}</td>
                        <td>
                            <div className="d-flex">
                                {item.IsLeader ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1210_6)">
                                            <path d="M5.99707 13.158C5.99707 13.9166 6.3281 14.6269 6.90051 15.1235L6.87293 15.1855H6.97637C6.98327 15.1855 6.98327 15.1924 6.99017 15.1924L9.96948 17.5442L10.0178 17.5855L13.0453 15.1993C13.0522 15.1993 13.0522 15.1924 13.0591 15.1855H13.1074V15.1442C13.6936 14.6476 14.0315 13.9304 14.0315 13.1648V8.02002H5.99707V13.158ZM8.7212 12.8545L10.0178 9.50968L11.3143 12.8545H8.7212ZM8.44534 13.5786H11.5902L12.135 14.9717L10.0178 16.6407L7.90051 14.9717L8.44534 13.5786ZM13.3005 8.75795V13.1648C13.3005 13.6062 13.135 14.0338 12.8315 14.3786L10.6453 8.76485H13.3005V8.75795ZM9.40396 8.75795L7.21086 14.3717C6.90741 14.0338 6.7419 13.6062 6.7419 13.158V8.75795H9.40396Z" fill="#0B1911" />
                                            <path d="M13.6678 6.59229H6.35742V7.60609H13.6678V6.59229Z" fill="#0B1911" />
                                            <path d="M10.0165 4.33548C10.4393 4.33548 10.782 3.99274 10.782 3.56996C10.782 3.14717 10.4393 2.80444 10.0165 2.80444C9.59367 2.80444 9.25098 3.14717 9.25098 3.56996C9.25098 3.99274 9.59367 4.33548 10.0165 4.33548Z" fill="#0B1911" />
                                            <path d="M6.3202 6.23196C6.74298 6.23196 7.08573 5.88922 7.08573 5.46645C7.08573 5.04366 6.74298 4.70093 6.3202 4.70093C5.89742 4.70093 5.55469 5.04366 5.55469 5.46645C5.55469 5.88922 5.89742 6.23196 6.3202 6.23196Z" fill="#0B1911" />
                                            <path d="M8.7831 6.23196C9.20585 6.23196 9.54861 5.88922 9.54861 5.46645C9.54861 5.04366 9.20585 4.70093 8.7831 4.70093C8.36034 4.70093 8.01758 5.04366 8.01758 5.46645C8.01758 5.88922 8.36034 6.23196 8.7831 6.23196Z" fill="#0B1911" />
                                            <path d="M10.4849 5.46655C10.4849 5.88724 10.8297 6.23206 11.2504 6.23206C11.6711 6.23206 12.0159 5.88724 12.0159 5.46655C12.0159 5.04586 11.6711 4.70103 11.2504 4.70103C10.8228 4.69413 10.4849 5.03896 10.4849 5.46655Z" fill="#0B1911" />
                                            <path d="M13.7128 6.23196C14.1356 6.23196 14.4783 5.88922 14.4783 5.46645C14.4783 5.04366 14.1356 4.70093 13.7128 4.70093C13.29 4.70093 12.9473 5.04366 12.9473 5.46645C12.9473 5.88922 13.29 6.23196 13.7128 6.23196Z" fill="#0B1911" />
                                            <path d="M10.0152 0.509277C4.67729 0.509277 0.339355 4.84721 0.339355 10.1852C0.339355 15.5231 4.67729 19.861 10.0152 19.861C15.3532 19.861 19.6911 15.5231 19.6911 10.1852C19.6911 4.84721 15.3532 0.509277 10.0152 0.509277ZM10.0152 18.8334C5.24281 18.8334 1.36694 14.9507 1.36694 10.1852C1.36694 5.41962 5.2497 1.53686 10.0152 1.53686C14.7807 1.53686 18.6635 5.41962 18.6635 10.1852C18.6635 14.9507 14.7876 18.8334 10.0152 18.8334Z" fill="#0B1911" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1210_6">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    :
                                    null
                                }
                                <span className="ms-2">
                                    {item.RankName}
                                </span>
                            </div>
                        </td>
                        <td>PKR {NumberFormat(item.Current_Balance)}</td>
                        <td>PKR {NumberFormat(item.PackagePrice)}</td>
                        <td>
                            <div className="button-row justify-content-center flex-nowrap">
                                <Link to={`/admin/userstatistics-dashboard/leadership-view-team/${item.Nodes_IN_PK_Code}?Full_Name=${item.Full_Name}`} className="button small-button text-nowrap" >
                                    View Team
                                </Link>
                            </div>
                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="16">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };
    const handleTeam = (event) => {
        setUserloading(true);
        const data = {
            UserName: Full_Name,
        };
        // console.log(data)

        var api_config = {
            method: "post",
            url: config.base_url + "Members/GetReferralUserByUserName",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data, '----------handleTeam');

                if (response.data.status_code == 1) {
                    setID(response.data.Nodes_IN_PK_Code);
                    // setUser(response.data.User_Name);
                    setUserloading(false);
                } else if (response.data.status_code == 0) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setUserloading(false);
                }
            })
            .catch(function (error) {
                toast.error("Something went wrong..", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };



    return (
        <>
            <ToastContainer />
            <AdminRightsCheck />

            <ReactTooltip />
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Leader Sale Progress for {User}
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/userstatistics-dashboard/leadership">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Package Name</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={MembershipName}
                                                    onChange={(e) => setMembershipName(e.target.value)}
                                                >
                                                    <option value="" selected>
                                                        All
                                                    </option>

                                                    <AllPackagesDropDown />
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Designation</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Designation}
                                                    onChange={(e) =>
                                                        setDesignation(e.target.value)
                                                    }
                                                >
                                                    <option value="">All</option>
                                                    <option value="Regional Sales Manager">Regional Sales Manager</option>
                                                    <option value="Sales Director">Sales Director</option>
                                                    <option value="Managing Director">Managing Director</option>
                                                    <option value="Country Head">Country Head</option>
                                                    <option value="District Sales Manager">District Sales Manager</option>
                                                    <option value="Sales Manager">Sales Manager</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Search by Rank</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={RankName}
                                                    onChange={(e) => setRankName(e.target.value)}
                                                >
                                                    <option value="" selected>
                                                        All
                                                    </option>
                                                    <option value="Pearl">Pearl</option>
                                                    <option value="Sapphire">Sapphire</option>
                                                    <option value="Emerald">Emerald</option>
                                                    <option value="Gold">Gold</option>
                                                    <option value="Black Gold">Black Gold</option>
                                                    <option value="Gold King">Gold King</option>
                                                    <option value="Diamond">Diamond</option>
                                                    <option value="Diamond Lion">Diamond Lion</option>
                                                    <option value="Diamond King">Diamond King</option>
                                                    <option value="Black Diamond King">Black Diamond King</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form_default position-relative">

                                            <div className="row align-items-end gy-3 gx-1">
                                                <div className="col-md-7">
                                                    <div className="form-group search_box">
                                                        <label htmlFor="">Search Member</label>
                                                        <div className="inputwrap">
                                                            <input type="text" className="form-control"
                                                                placeholder="Find by Username/Member ID/Email"
                                                                value={Full_Name}
                                                                onChange={(e) => setFull_Name(e.target.value)}
                                                            />
                                                            <span className="material-symbols-outlined">search</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <button type="submit" onClick={() => handleSearch()} className="button w-100 button-primary input-height input-radius">
                                                        search
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <button onClick={(event) => handleTeam()} className="button button-primary input-height input-radius">
                                            View Team
                                        </button>
                                    </div>
                                    <div className="col-md-1">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/userstatistics-dashboard/leadership-view-team/pdf?StartDate=${StartDate}&EndDate=${EndDate}&Full_Name=${Full_Name}&NoOfLevel=${NoOfLevel}&MemberType=${MemberType}&RankName=${RankName}&Postal_Address=${Designation}&PackageID=${MembershipName}&ID=${userstatus.id}`}>PDF</Link>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Created Date</th>
                                    <th>Purchased Date</th>
                                    <th>Member Name</th>
                                    <th>Username</th>
                                    <th>Package Name</th>
                                    <th>Email</th>
                                    <th>Designation</th>
                                    <th>Member Rank</th>
                                    <th>Cash Balance</th>
                                    <th>Total Packages</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="12">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th colSpan={10} className="text-end border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'PackagePrice'))}</th>
                                                    <td className="border-bottom-0 pt-3"></td>

                                                </tr>
                                                <tr className="">
                                                    <th colSpan={10} className="text-end">Grand Total:</th>
                                                    <th>PKR {NumberFormat(GrandTotal.totalPackages)}</th>
                                                    <th></th>
                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
export default ViewTeam