import React, { useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import config from '../../Config';
import axios from 'axios';
import logo from '../../assets/images/logo/mag.svg';
import background from '../../assets/images/partner/login-bg.png';
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArrowLeftEndOnRectangleIcon, ArrowLongLeftIcon, ArrowLongRightIcon, EnvelopeIcon, EyeIcon, EyeSlashIcon, KeyIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import ReCAPTCHA from 'react-google-recaptcha';


const Login = () => {


  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  let navigate = useNavigate();

  const [VerificationCode, setVerificationCode] = useState("");
  const [Email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [inputstyle, setinputstyle] = useState(true);
  const [Error, setError] = useState(false);
  const [NextStep, setNextStep] = useState(false);
  const [ResponseEmail, setResponseEmail] = useState('');
  const [FixCode, setFixCode] = useState('');
  const [Password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  }




  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (captchaValue) {
        setLoading(true);
        const signInData = {
          Email_Address: Email,
          Password: Password,
          Email_Verification_Code: VerificationCode,
        };

        var api_config = {
          method: "post",
          url: config.base_url + "Members/SignIn",
          headers: {
            "Content-Type": "application/json",
          },
          data: signInData,
        };
        axios(api_config)
          .then(function (response) {
            // console.log(response.data);
            if (response.data.status_code == 1) {
              setResponseEmail(response.data.Email_Address)

              // toast.success("You are Logged in..Wait..", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              setNextStep(true);


            } else {
              toast.error(response.data.status_message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            toast.error("Network Error..", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Please complete the CAPTCHA", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setValidated(true);
  };
  const handleEmailCheck = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setinputstyle(true);
      const data = {
        Email_Address: ResponseEmail,
        SecurityCode: VerificationCode,
      };

      // console.log(data);

      var api_config = {
        method: 'post',
        url: config.base_url + 'Members/VerifiySigninOTP',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(api_config)
        .then(function (response) {

          // console.log(response.data);
          if (response.data.status_code == 1) {
            setIsButtonDisabled(true);

            localStorage.setItem("AccessKey", response.data.AccessKey);
            localStorage.setItem("ID", response.data.ID);
            localStorage.setItem("Full_Name", response.data.Full_Name);
            localStorage.setItem("Email_Address", response.data.Email_Address);
            localStorage.setItem("Img_File", response.data.Img_File);
            localStorage.setItem("User_Name", response.data.User_Name);
            localStorage.setItem("theme", "light");

            navigate("/partner/dashboard");
            window.scrollTo(0, 0);
            setError(false);

            toast.success(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          else {
            setinputstyle(false);
            toast.error(response.data.status_message, {
              position: toast.POSITION.TOP_RIGHT
            });
            setFixCode(VerificationCode);
            setError(true);
          }
          setLoading(false);
        })
        .catch(function (error) {
          setError(false);
          setLoading(false);
          toast.error('Network Error..', {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
  };


  return (
    <>
      <ToastContainer />
      <section className='business-signin-form login-page'>
        <img src={background} />
        <div className='container'>
          <div className="login_box">
            {
              !NextStep ?
                <>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="login_box_head">
                      <img src={logo} />
                      <h4>Login to app, enter your Login ID and Password</h4>
                    </div>
                    <div className="login_box_body">
                      <div className="icon-input">
                        <input
                          type="text"
                          name={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder='Login ID'
                          className=""
                          required
                          style={{ borderColor: inputstyle ? '' : 'red' }}
                        />
                        <div className="icon">
                          <UserCircleIcon />
                        </div>
                      </div>
                      <div className="icon-input m-0">
                        <input
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          value={Password}
                          placeholder='Login Password'
                          required
                        />
                        <div className="icon" onClick={togglePasswordVisibility} >
                          {
                            showPassword ?
                              <EyeSlashIcon />
                              :
                              <EyeIcon />
                          }
                        </div>
                      </div>
                      <Link className='link' to={`/forgot-password`}>Forgot Password ?</Link>
                      <div className="mb-3">
                        <ReCAPTCHA
                          theme="light"
                          sitekey="6LfnFz0qAAAAAAblPmVjM6m4G_oG_4DxLAAtY6UE"
                          onChange={handleCaptchaChange}
                        />
                      </div>
                      <button style={{ position: "relative", zIndex: 2 }} type="submit" className="button button-bs w-100 input-height input-radius"
                        disabled={loading}>
                        LOGIN {loading ? '...' : ''}<span><ArrowLeftEndOnRectangleIcon /></span>
                      </button>
                      <NavLink to={'/signup'} className='mt-3 d-flex m-auto justify-content-center'>SIGN UP</NavLink>
                    </div>
                  </Form>
                </>

                :
                <>
                  <Form noValidate validated={validated} onSubmit={handleEmailCheck}>
                    <div className="login_box_head">
                      <img src={logo} />
                      <h4>Check your Email and Enter the Verification Code Sent to your Email address for Login.</h4>
                    </div>
                    <div className="login_box_body">
                      <div className="icon-input">
                        <input
                          autoComplete='false'
                          type="text"
                          onChange={(e) => setVerificationCode(e.target.value)}
                          value={VerificationCode}
                          placeholder='Enter Verification Code'
                          required
                          style={{ borderColor: inputstyle ? '' : 'red' }}
                        />
                        <div className="icon">
                          <KeyIcon />
                        </div>
                      </div>
                      <p className={`error-massage ${Error ? '' : 'd-none'}`}>
                        <b>Error! Invalid Email Confirmation Code!</b>
                        <br />
                        You passed '{FixCode}'. You can only try this code 2 more time(s)
                        before it will be invalidated an a new one generated.
                        It may take a few minutes to arrive.
                        If you are unable to complete the login process or receive the email please contact {''}
                        <a href="mailto:connect@mag.holdings">connect@mag.holdings</a> for further assistance.
                      </p>
                      <div className="button-row justify-content-between">
                        <button type='button' className="input-height input-radius button button-outline-bs" style={{ width: '46%' }} onClick={() => setNextStep(false)}>
                          <span><ArrowLongLeftIcon /></span>Back
                        </button>
                        <button type="submit" className="button button-bs input-height input-radius" style={{ width: '46%' }}
                          disabled={loading}>
                          Proceed {loading ? '...' : ''}<span><ArrowLongRightIcon /></span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
