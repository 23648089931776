import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AdminRightsCheck from "../AdminRightsCheck";
import { Badge } from "react-bootstrap";
import { NumberFormat } from "../../Dashboard/General/Functions";
import Form from 'react-bootstrap/Form';
import AdminCard from "../AdminCard";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import ReactTooltip from "react-tooltip";
import { calculateColumnTotal } from "../../Common/Util";

function MyTeamLeadershipHistory(props) {
    const userstatus = useParams();
    const userstatusVal = userstatus.userstatus;


    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [validated, setValidated] = useState(false);

    const [isUpdated, setIsUpdated] = useState(false);
    const [Updated, seUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [DetailLoading, setDetailLoading] = useState(false);
    const [TargetLoading, setTargetLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    //   console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();


    const [showFilters, setShowFilters] = useState(true);

    const [AllMembers, SetAllMembers] = useState(false);
    const [Status, setStatus] = useState('All');
    const [Amount, setAmount] = useState('');
    const [CommissionRate, setCommissionRate] = useState('');
    const [TargetStartDate, setTargetStartDate] = useState('');
    const [TargetEndDate, setTargetEndDate] = useState('');
    const [TargetUser, setTargetUser] = useState('');
    const [DetailShow, setDetailShow] = useState(false);


    const [OTP, setOTP] = useState('');
    const [CommissionAmount, setCommissionAmount] = useState('');
    const [AmountLoading, setAmountLoading] = useState(false);

    const [IsDelect, setIsDelect] = useState(false);

    const [TargetData, setTargetData] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');
    const [Designation, setDesignation] = useState('');

    const param = useParams();

    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            MemberID: AllMembers ? '0' : param.id,
            RecordType: Status,
            startDate: StartDate,
            endDate: EndDate,
            Postal_Address: Designation,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderCommissionList",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofLedger);
                    setLoading(false);
                    setGrandTotal(response.data);
                    setTotalRecords(response.data.totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
            });
    }, [pageNo, pageSize, isUpdated, StartDate, EndDate, TargetLoading, IsDelect, Updated, AmountLoading, Status, AllMembers, Designation]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const [showCommissionModal, setshowCommissionModal] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [User_ID, setUser_ID] = useState(null);
    const handleShowModal = (id) => {
        setShowModal(true);
        setDetailLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: id,
            startDate: StartDate,
            endDate: EndDate,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderCommissionStatus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setTargetData(response.data);
                    setDetailLoading(false);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });


    }


    const CommissionShowModal = (ID) => {
        setshowCommissionModal(true);
        setTargetUser(ID);
    }



    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{item?.SelectedMember?.Full_Name}</td>
                        <td>{item?.SelectedMember?.User_Name}</td>
                        <td>{item?.SelectedMember?.User_Name}</td>
                        <td>PKR {NumberFormat(item.AchievedTarget)}</td>
                        <td>PKR {NumberFormat(item.RemainingTarget)}</td>
                        <td>PKR {NumberFormat(item.RemainingTarget)}</td>
                        <td>PKR {NumberFormat(item.RemainingTarget)}</td>
                        <td>PKR {NumberFormat(item.TargetAmount)}</td>
                        <td>PKR {NumberFormat(item.AchievedTarget / 100 * item.CommissionPercentage)}</td>
                        <td>{NumberFormat(item.CommissionAmount)}</td>
                        <td>{item.CommissionPaidDate != null && moment(item.CommissionPaidDate).format('L')}</td>
                        <td>{item.CommissionPercentage} %</td>
                        <td>{item.CommissionPaid ? <Badge bg="dark">Paid</Badge> : <Badge bg="danger">Pending</Badge>}</td>
                        <td>{moment(item.StartDate).format('L')} <br /> {moment(item.EndDate).format('L')}</td>
                        <td>
                            <ul
                                className="inline-action justify-content-start flex-nowrap"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                <li>
                                    <button
                                        className="black"
                                        onClick={(id) => handleShowModal(item.ID)}
                                    >
                                        <span class="material-symbols-outlined">
                                            fullscreen
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={(ID, FullName) => handleDetail(item.ID, item?.SelectedMember?.Full_Name)}
                                        className="primary "
                                    >
                                        <i className="ri-edit-line"></i>
                                    </button>
                                </li>
                                <li><Link className="red" onClick={(e) => DelectItem(item.ID)} type="button"><i className="ri-delete-bin-line"></i></Link></li>
                                {item.CommissionPaid ?
                                    ""
                                    :
                                    <li>
                                        <button className="gray"
                                            onClick={(ID) => CommissionShowModal(item.ID)}
                                            type="button" >
                                            <span className="material-symbols-outlined">
                                                send_money
                                            </span>
                                        </button>
                                    </li>
                                }
                            </ul>
                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="16">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleDetail = (ID, FullName) => {
        setDetailShow(true);
        setUser_ID(ID);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderTargetByID",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data, 'by id');
                if (response.data.status_code == 1) {
                    setTargetStartDate(moment(response.data.LeaderCommission?.StartDate).format("YYYY-MM-DD"));
                    setTargetEndDate(moment(response.data.LeaderCommission?.EndDate).format("YYYY-MM-DD"));
                    setAmount(response.data.LeaderCommission?.TargetAmount);
                    setCommissionRate(response.data?.LeaderCommission?.CommissionPercentage);

                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });


    }



    const DelectItem = (ID) => {
        if (window.confirm('Are you sure to delete the Target')) {

            setIsDelect(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                ID: ID,
            };

            // console.log('data', data);

            var api_config = {
                method: 'post',
                url: config.base_url + 'LeaderCommission/DeleteLeaderTargetByID',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };


            axios(api_config)
                .then(function (response) {

                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsDelect(false);
                    }
                    else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setIsDelect(false);
                    }
                })
                .catch(function (error) {
                    toast.error(error.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                });
        }
    };
    
  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsUpdated(true);
  };


    return (
        <>
            <ToastContainer />
            <AdminRightsCheck />
            <ReactTooltip />

            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            My Team Leadership Commission History
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/userstatistics-dashboard/leadership" className="button button-outline-bs">
                                Back
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Select Status</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <option value="All"> All </option>
                                                    <option value="Paid">Paid</option>
                                                    <option value="Pending">Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <Form.Check
                                            className="mb-md-3"
                                            onClick={() => SetAllMembers(!AllMembers)}
                                            value={AllMembers}
                                            type={'checkbox'}
                                            id={`AllMembers`}
                                            label={`View All Members`}
                                        />


                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Designation</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Designation}
                                                    onChange={(e) =>
                                                        setDesignation(e.target.value)
                                                    }
                                                >
                                                    <option value="" disabled>All</option>
                                                    <option value="Regional Sales Manager">Regional Sales Manager</option>
                                                    <option value="Sales Director">Sales Director</option>
                                                    <option value="Managing Director">Managing Director</option>
                                                    <option value="Country Head">Country Head</option>
                                                    <option value="District Sales Manager">District Sales Manager</option>
                                                    <option value="Sales Manager">Sales Manager</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/userstatistics-dashboard/leadership-view/pdf?StartDate=${StartDate}&EndDate=${EndDate}&Full_Name=${Full_Name}&ID=${AllMembers ? '0' : param.id}&Status=${Status}`}>PDF</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Member Name</th>
                                    <th>Username</th>
                                    <th>Designation</th>
                                    <th>Achieved Target</th>
                                    <th>Remaining Target</th>
                                    <th>Target Amount</th>
                                    <th>Upline Leadership Commission</th>
                                    <th>Upline Commission Percentage</th>
                                    <th>Commission Calculation</th>
                                    <th>Paid Commission</th>
                                    <th>Paid Commission Date</th>
                                    <th>Commission Percentage</th>
                                    <th>Status</th>
                                    <th>Start Date <br />End Date</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="16">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                        {
                                            listOfLedger != null && listOfLedger.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th colSpan={6} className="border-bottom-0 pt-3"></th>
                                                    <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Current_Balance'))}</th>
                                                    <td className="border-bottom-0 pt-3"></td>

                                                </tr>
                                                <tr className="">
                                                    <th className="pt-3" colSpan={6}></th>
                                                    <th className="text-center">Grand Total:</th>
                                                    <th>PKR {NumberFormat(GrandTotal.totalBalance)}</th>

                                                    <td style={{ borderButtom: "1px solid !important" }}></td>

                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
export default MyTeamLeadershipHistory