import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../../Dashboard/General/Pagination";
import { Cropper } from "react-cropper";
import AdminRightsCheck from "../AdminRightsCheck";
import { Modal } from "react-bootstrap";
import { ViewfinderCircleIcon, TrashIcon } from "@heroicons/react/24/outline";

function ManageUsers(props) {
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
  const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));
  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [money, setMoney] = useState('');
  const [Remarks, SetRemarks] = useState('');
  const [moneyID, setMoneyID] = useState('');
  const [PinCode, setPinCode] = useState('');


  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [FullName, setFullName] = useState("");

  const [VerificationCode, setVerificationCode] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [inputstyle, setinputstyle] = useState(true);
  const [timer, setTimer] = useState(false);

  const [imageCropped, setImageCropped] = useState(false);
  const [imageSelected, setImageSelected] = useState(false);
  const [image, setImage] = useState('');

  const [AttachImg, setAttachImg] = useState(null);
  const [cropper, setCropper] = useState();
  const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const [dragging, setDragging] = useState(false);

  // pagination 
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);


  useEffect(() => {
    document.title = "Mag Holdings Admin";

    setIsUpdated(false);
    setLoading(true);
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: "Admin",
      pageNo: pageNo,
      pageSize: pageSize,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/GetAllUsers',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dashboardParms
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        setLedger(response.data.ListofRecords);
        setLoading(false);
        setTotalRecords(response.data.totalRecords);

      })
      .catch(function (error) {
        // console.log(error);
      });

  }, [pageNo, pageSize, isUpdated]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };


  const renderLedger = () => {

    if (listOfLedger.length > 0) {
      return (
        listOfLedger.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item.ID}</td>
              <td>{item.Full_Name}</td>
              <td>{item.Email_Address}</td>
              <td>{item.Group_Name}</td>
              <td>{item.Investment_Balance}</td>
              <td>{item.Distribution_Balance}</td>
              <td>
                <ul className="inline-action" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <Link className="primary" to={'/admin/security-dashboard/manage-users/edit/' + item.ID}  >
                      <span className="material-symbols-outlined">
                        edit
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="red" onClick={(e) => deleteUsers(item.ID)} type="button" >
                      <span className="material-symbols-outlined">
                        delete
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="gray" onClick={(ID, name) => handleBalance(item.ID, item.Full_Name)} type="button" >
                      <span className="material-symbols-outlined">
                        send_money
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="black" onClick={(ID, name) => handleCashMoney(item.ID, item.Full_Name)} type="button" >
                      <span className="material-symbols-outlined">
                        send
                      </span>
                    </Link>
                  </li>

                </ul>
              </td>
            </tr>
          )
        })
      )
    }
    else {
      return (
        <tr>
          <td colSpan="8"><h4 className="mt-3" style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}>No Data Found</h4></td>
        </tr>
      )
    }

  }

  const deleteUsers = (ID) => {

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Security/DeleteUser',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {

          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(true);
        }
        else if (response.data.status_code == 0) {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
        // setIsUpdated(false);

      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handleMoney = () => {
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: moneyID,
      Amount: money,
      PINCode: PinCode,
      AdminRemarks: Remarks,
    };
    //  console.log(data)
    var api_config = {
      method: 'post',
      url: config.base_url + 'Users/SendBalanceToUser',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        //  console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(true);
          setShow(false);
        }
        else if (response.data.status_code == 0) {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
        // setIsUpdated(false);

      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handleCash = () => {
    let base64_string = "";

    if (typeof cropper !== "undefined") {

      var cropData1 = cropper.getCroppedCanvas().toDataURL();
      setImageCropped(true);
      var cleanerBase64 = cropData1.substring(22);
      base64_string = cleanerBase64;


    }
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: moneyID,
      Amount: money,
      PINCode: PinCode,
      AdminRemarks: Remarks,
      FileAttached: base64_string,
    };
    // console.log(data)
    var api_config = {
      method: 'post',
      url: config.base_url + 'Users/SendCashToUser',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsUpdated(true);
          setShowModal(false);
        }
        else if (response.data.status_code == 0) {

          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
        // setIsUpdated(false);

      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handleClose = () => {
    setShow(false);

  };
  const handleBalance = (ID, name) => {
    setFullName(name);
    setMoneyID(ID);
    setShow(true);
  };
  const handleCashMoney = (ID,name) => {
    setFullName(name);
    setMoneyID(ID);
    setShowModal(true);
  };
  const handleMoneyNull = () => {
    setMoneyID('');
    setMoney('');
    setPinCode('');
    DeleteImage();
    setFullName('');
    setVerificationCode('');
  };

  const onChange = (e) => {
    e.preventDefault();
    setImageSelected(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const file = files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setImageSelected(false)
      toast.error("Image size must be less than 2 MB", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event) => {

    event.preventDefault();
    setImageSelected(true);
    setDragging(false);

    const file = event.dataTransfer.files[0];
    if (file) {

      if (file.size > MAX_IMAGE_SIZE) {

        setImageSelected(false)
        toast.error("Image size must be less than 2 MB", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      // console.log(file.size);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        if (cropper) {
          cropper.replace(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const DeleteImage = () => {
    setImageSelected(false);
    setImage('');
    setCropper();
  }


  const handleCloseCashModal = () => {
    setShowModal(false);
  }
  const handleNullCashModal = () => {
    setShowModal(false);
    setImageSelected(false);
    setVerificationCode('');
    setFullName('');
    setImage('');
    setCropper();
    setMoneyID('');
    setMoney('');
    SetRemarks('');
    setPinCode('');
  }
  const handleEmailCheck = (event) => {
    setIsButtonDisabled(true);
    setinputstyle(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log(data);

    var api_config = {
      method: 'post',
      // url: config.base_url + 'Users/SendBalanceTransferOTP',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {

        // console.log(response.data);
        if (response.data.status_code == 1) {
          setTimeout(() => {
            setIsButtonDisabled(false);
          }, 60000);
          setTimer(60);
          toast.success(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error('Network Error..', {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  };

  return (
    <>
      <AdminRightsCheck />
      <ToastContainer />

      <Modal className="bs-modal" show={show} onHide={handleClose} onExit={handleMoneyNull}>
        <Modal.Header closeButton>
          <Modal.Title>Send Balance To {FullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="icon-form-group">
            <label htmlFor="">Amount</label>
            <div className="icon-input">
              <input type="text" placeholder="Enter Amount" onChange={(e) => setMoney(e.target.value)} value={money} />
            </div>
          </div>
          <div className="icon-form-group">
            <label htmlFor="">Remarks</label>
            <div className="icon-input">
              <input type="text" placeholder="Enter Remarks" onChange={(e) => SetRemarks(e.target.value)} value={Remarks} />
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="icon-form-group">
              <label htmlFor="">OTP</label>

              <div className="icon-input mb-0">
                <input
                  type="text"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  name={VerificationCode}
                  placeholder=""
                  className="form-control"
                  required
                />
                <div className="icon">
                  <button type="button"
                    disabled={isButtonDisabled}
                    style={{ width: 'fit-content', border: '50px', fontWeight: '400', textTransform: 'none', boxShadow: 'none' }}
                    onClick={() => handleEmailCheck()}
                    className="button small-button">
                    Get Code
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="icon-form-group">
            <div className="icon-input">
              <input type="text" placeholder="XXXXXX" className="text-center" onChange={(e) => setPinCode(e.target.value)} value={PinCode} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button button-primary" type="submit" onClick={handleMoney}>
            Send Balance
          </button>
        </Modal.Footer>
      </Modal>
      <Modal className="bs-modal" show={showModal} onHide={handleCloseCashModal} onExit={handleNullCashModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send Cash To {FullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="icon-form-group">
            <label htmlFor="">Amount</label>
            <div className="icon-input">
              <input type="text" placeholder="Enter Amount" onChange={(e) => setMoney(e.target.value)} value={money} />
            </div>
          </div>
          <div className="icon-form-group">
            <label htmlFor="">Remarks</label>
            <div className="icon-input">
              <input type="text" placeholder="Remarks" onChange={(e) => SetRemarks(e.target.value)} value={Remarks} />
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="icon-form-group">
              <label htmlFor="">OTP</label>

              <div className="icon-input mb-0">
                <input
                  type="text"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  name={VerificationCode}
                  placeholder=""
                  className="form-control"
                  required
                />
                <div className="icon">
                  <button type="button"
                    disabled={isButtonDisabled}
                    style={{ width: 'fit-content', border: '50px', fontWeight: '400', textTransform: 'none', boxShadow: 'none' }}
                    onClick={() => handleEmailCheck()}
                    className="button small-button">
                    Get Code
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="icon-form-group">
            <div className="icon-input">
              <input type="text" placeholder="XXXXXX" className="text-center" onChange={(e) => setPinCode(e.target.value)} value={PinCode} />
            </div>
          </div>
          <div className="icon-form-group">
            <label htmlFor="">Attached File</label>
          </div>
          <div className="upload-img gray my-3 w-100">
            <div className="card-inner">
              <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                id="drop-area"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                {
                  imageSelected ?
                    <>
                      <Cropper
                        // style={{ width: '550px', height: '220px;', marginTop: '-10px' }}
                        zoomTo={0.5}
                        preview=".img-preview"
                        src={image}
                        dragMode="move"
                        viewMode={3}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                      <div className="delete" onClick={DeleteImage}>
                        <TrashIcon />
                      </div>
                    </>

                    :
                    <>
                      <label htmlFor="ChoosePassportimage" className='thumnail'>
                        <ViewfinderCircleIcon />
                        <h6>Drag and drop your document</h6>
                        <p>Max size 2 MB. Files allowed: JPG, PNG</p>
                      </label>
                    </>
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button button-primary" type="submit" onClick={handleCash}>
            Send Cash
          </button>
        </Modal.Footer>
      </Modal>

      <div className="admin-content">
        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              MANAGE USERS
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to="/admin/security-dashboard">
                <button type="button" className="button button-outline-bs">
                  Back
                </button>
              </NavLink>
              <NavLink to="/admin/security-dashboard/manage-users/add">
                <button type="button" className="button button-primary  ms-3">
                  Add User
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  <th>Group</th>
                  <th>Available Balance</th>
                  <th>Cash</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="10">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalRecords > 10 && (
          <div className="pagination-wrap">
            <div className="row align-items-center">
              <div className="col-md-2 form_default">
                <div className="d-flex align-items-center">
                  <label className="me-2" style={{ fontSize: "15px" }}>
                    Page size
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        setPageNo(1);
                        setPageSize(parseInt(e.target.value));
                      }}
                    >
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>
              </div>

              <Pagination
                pageNo={pageNo}
                pageSize={pageSize}
                totalRecords={totalRecords}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ManageUsers;
