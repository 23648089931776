import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { NavLink, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Form, PageItem } from "react-bootstrap";
import Pagination from "../../Dashboard/General/Pagination";


function Events() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID")
  const MemberType = localStorage.getItem("MemberType");
  const [showFilters, setShowFilters] = useState(true);

  const [loading, setLoading] = useState(false);
  const [listOfLedger, setLedger] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [Title, setTitle] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Manage Events";

    setIsUpdated(false);
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      MemberType: MemberType,
      pageNo: pageNo,
      pageSize: pageSize,
      Title: Title,
    };
    //console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "Events/GetAllEvents",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {

          //console.log(response.data);
          setLedger(response.data.ListofRecords);
          setLoading(false);
          setTotalRecords(response.data.totalRecords);
        }
        else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/web-admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT
          });
          setLoading(false);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, [pageNo, pageSize, isUpdated, Title]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setTitle(Title)
    }

    setValidated(true);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const renderLedger = () => {
    if (listOfLedger != null && listOfLedger.length > 0) {
      let count = 1;
      return listOfLedger.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.ID}</td>
            <td className="text-nowrap">{moment(item.Dated).format('DD-MMM-YYYY')}</td>
            <td>{item.Title}</td>
            <td>{item.Source}</td>
            <td>{item.Views}</td>

            <td>
              <ul
                className="inline-action"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to={"/web-admin/edit-events/" + item.ID}
                    className="primary"
                  >
                    <i className="ri-edit-line"></i>
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => DeleteEvent(item.ID)}
                    className="red"
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colspan="9">
            <h4
              className="mt-3"
              style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
            >
              No Data Found
            </h4>
          </td>
        </tr>
      );
    }
  };
  const DeleteEvent = (ID) => {
    if (window.confirm('Are you sure you want to proceed with this process?')) {
      const data = {
        AccessKey: AccessKey,
        UserID: UserID,
        ID: ID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Events/DeleteEvent",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(api_config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.status_code == 1) {
            setIsUpdated(true);
          }

        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="admin-content">

        <div className="row align-items-center gy-3">
          <div className="col-md-6">
            <div className="d-title m-0">
              Photo Gallery
            </div>
          </div>
          <div className="col-md-6 text-md-end text-center">
            <div className="">
              <NavLink to={`/admin/add-events`}
                className="button button-primary text-white ms-3"
                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
              >
                Add Photo Gallery
                <i className="ri-menu-line ms-3"></i>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          className={`collapse ${showFilters ? "show" : ""} `}
          id="collapseFilteres"
        >
          <div>
            <div className="card card-table mb-3 mt-4">
              <div className="card-inner">
                <div className="form_default">
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row align-items-end p-4 gy-3">
                      <div className="col-md-10">
                        <div className="form_default">
                          <div className="form-group">
                            <label htmlFor="">Title:</label>
                            <div className="inputwrap">
                              <input
                                type="text"
                                className="form-control"
                                value={Title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <button
                            type="submit"
                            value="Search"
                            className="w-100 button button-primary input-height input-radius"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-table mb-5 mt-3">
          <div className="card-inner responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th style={{ width: "115px" }}>Dated</th>
                  <th>Title</th>
                  <th>Source</th>
                  <th>Views</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colspan="9">
                      <Spinner animation="border" role="status"></Spinner>
                    </td>
                  </tr>
                ) : (
                  <>{renderLedger()}</>
                )}
              </tbody>
            </table>
            {totalRecords > 10 && (
              <div className="pagination-wrap">
                <div className="row align-items-center">
                  <div className="col-md-2 form_default">
                    <div className="d-flex align-items-center">
                      <label className="me-2" style={{ fontSize: "15px" }}>
                        Page size
                      </label>
                      <div className="form-group">
                        <select
                          className="form-select"
                          style={{ width: "100px" }}
                          onChange={(e) => {
                            setPageNo(1);
                            setPageSize(parseInt(e.target.value));
                          }}
                        >
                          <option value="10" selected>
                            10
                          </option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <Pagination
                    pageNo={pageNo}
                    pageSize={pageSize}
                    totalRecords={totalRecords}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Events;
