import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Editor } from "@tinymce/tinymce-react";
import config from "../../../Config";


function AddEvents(props) {

    document.title = 'Add Events'
    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID")
    const MemberType = localStorage.getItem("MemberType");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    // photo 

    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState('');

    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);


    const DeleteImage = () => {
        setImageSelected(false);
        setImage('');
        setCropper();
    }

    const MAX_IMAGE_SIZE = (2 * 1024) * 1024; // 2MB in bytes



    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState("");
    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
    };

    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setImageSelected(false)
            toast.error("Image size must be less than 5 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };


    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDrop = (event) => {

        event.preventDefault();
        setImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setImageSelected(false)
                toast.error("Image size must be less than 5 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            //console.log(file.size);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                //console.log(e.target.result);
                if (cropper) {
                    cropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    let navigate = useNavigate();


    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            let base64_string = "";
            let remarks = event.target.remarks.value;

            if (typeof cropper !== "undefined") {

                var cropData1 = cropper.getCroppedCanvas().toDataURL();
                setImageCropped(true);
                var cleanerBase64 = cropData1.substring(22);
                base64_string = cleanerBase64;


            }

            if (base64_string != "") {
                setLoading(true);
                const data = {
                    AccessKey: AccessKey,
                    UserID: UserID,
                    MemberType: MemberType,
                    Dated: event.target.Dated.value,
                    Title: event.target.Title.value,
                    Source: event.target.Source.value,
                    Detail: editorContent,
                    // Thumbnail: cropData,
                    Large_Image: base64_string,
                    Admin_Remarks: remarks == "on" ? true : false,
                    Meta_Title: event.target.Meta_Title.value,
                    Meta_Description: event.target.Meta_Description.value,
                    Meta_Keywords: event.target.Meta_Keywords.value,
                    Thumbnail: base64_string
                };


                //console.log(data);

                var api_config = {
                    method: "post",
                    url: config.base_url + "/Events/AddEvent",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: data,
                };

                axios(api_config)
                    .then(function (response) {
                        //console.log(response.data);
                        if (response.data.status_code == 1) {
                            toast.success(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            navigate(`/web-admin/events`);
                        }
                        else if (response.data.status_message === "Invalid Access Key.") {
                            navigate("/web-admin/login");
                        } else {
                            toast.error(response.data.status_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }

                        setLoading(false);
                    })
                    .catch(function (error) {
                        toast.error("Network Error..", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }
        }

        setValidated(true);
    };


    return (
        <>
            <ToastContainer />
            <div className="admin-content">

                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Add Photo Gallery
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink
                                to={`/admin/events`}
                                className="button button-primary text-white ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                Photo Gallery
                                <i className="ri-menu-line ms-3"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>



                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div className="row align-items-end p-4 gy-3">

                        <div className="col-md-9">
                            <div className="card" style={{ minHeight: "210px" }}>
                                <div className="card-body">
                                    <div className="row gy-3">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Title:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="Title"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="">
                                                    Source:
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="Source"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="">
                                                    Dated:
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    required
                                                    name="Dated"
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="">
                                                        Short Description:
                                                    </label>
                                                    <textarea
                                                        rows={3}
                                                        className="form-control"
                                                        required
                                                        name="Short_Description"
                                                    />
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className={`upload-img-card pink`}>
                                <div className="card-inner">
                                    <div className="upload-img gray w-100" style={{ height: '210px' }}>
                                        <div className="card-inner">
                                            <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                                id="drop-area"
                                                onDragOver={handleDragOver}
                                                onDrop={handleDrop}
                                            >
                                                <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                                {
                                                    imageSelected ?
                                                        <>
                                                            <Cropper
                                                                style={{ height: '210px' }}
                                                                zoomTo={0.5}
                                                                preview=".img-preview"
                                                                src={image}
                                                                dragMode="move"
                                                                viewMode={3}
                                                                minCropBoxHeight={10}
                                                                minCropBoxWidth={10}
                                                                background={false}
                                                                responsive={true}
                                                                autoCropArea={4 / 4}
                                                                aspectRatio={4 / 4}
                                                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                                onInitialized={(instance) => {
                                                                    setCropper(instance);
                                                                }}
                                                                guides={true}
                                                            />
                                                            <div className="delete" onClick={DeleteImage}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                                </svg>
                                                            </div>
                                                        </>

                                                        :
                                                        <>
                                                            <label htmlFor="ChoosePassportimage" className='thumnail'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                                </svg>
                                                                <h6>Drag and drop your document</h6>
                                                                <p>Max size 2 MB. Files allowed: JPG, PNG</p>
                                                            </label>

                                                        </>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label className="">Page Details :</label>
                                        <Editor
                                            apiKey={config.Editor_Apikey}
                                            init={{
                                                height: 400,
                                            }}
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row gy-3">
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label className="">
                                                    SEO Title:
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="Meta_Title"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="">
                                                    SEO Keywords:
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    name="Meta_Keywords"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="">
                                                    SEO Description:
                                                </label>
                                                <textarea
                                                    rows={3}
                                                    className="form-control"
                                                    required
                                                    name="Meta_Description"
                                                />
                                            </div>
                                        </div>
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                required
                                                label="Agree to terms and conditions"
                                                feedback="You must agree before submitting."
                                                feedbackType="invalid"
                                                name="remarks"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-2 offset-md-10">
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    value="Save"
                                                    className="button button-primary w-100"
                                                    disabled={loading}
                                                >
                                                    SAVE {loading ? '...' : ''}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            </div>
        </>
    );
}

export default AddEvents;
