import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../../Dashboard/General/Pagination";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { calculateColumnTotal } from "../../Dashboard/General/Util";
import Form from 'react-bootstrap/Form';
import AdminCard from "../AdminCard";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import ReactTooltip from "react-tooltip";

function Leadership(props) {
    const userstatus = useParams();

    const [AccessKey, setAccessKey] = useState(
        localStorage.getItem("AdminAccessKey")
    );
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

    const [isUpdated, setIsUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [TargetLoading, setTargetLoading] = useState(false);
    const [listOfLedger, setLedger] = useState([]);
    //   console.log(status);

    // pagination
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    let navigate = useNavigate();


    const [showFilters, setShowFilters] = useState(true);

    const [Amount, setAmount] = useState('');
    const [CommissionRate, setCommissionRate] = useState('');
    const [TargetStartDate, setTargetStartDate] = useState('');
    const [TargetEndDate, setTargetEndDate] = useState('');
    const [TargetUser, setTargetUser] = useState('');


    const [StartDate, setStartDate] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [Full_Name, setFull_Name] = useState('');
    const [GrandTotal, setGrandTotal] = useState('');

    const [Designation, setDesignation] = useState('');


    useEffect(() => {
        document.title = "Mag Holdings Admin";

        setIsUpdated(false);
        setLoading(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: StartDate,
            endDate: EndDate,
            UserName: Full_Name,
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "Users/GetLeaders",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    setLedger(response.data.ListofRecords);
                    setLoading(false);
                    setGrandTotal(response.data);
                    setTotalRecords(response.data.totalRecords);
                    //console.log(totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }, [pageNo, pageSize, isUpdated, StartDate, EndDate, TargetLoading,Designation]);

    const handlePageChange = (page) => {
        setPageNo(page);
    };



    const [showModal, setShowModal] = useState(false);
    const [User_ID, setUser_ID] = useState(null);
    const handleShowModal = (ID) => {
        setShowModal(true);

        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ID: ID,
        };

        // console.log("data", data);

        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/GetLeaderCommissionStatus",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {

                    //console.log(totalRecords);
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });


    }

    const handleCloseModal = (closeModal) => {
        setShowModal(closeModal);
        setUser_ID('');
    }
    const [DetailShow, setDetailShow] = useState(false);
    const handleDetail = (ID, FullName) => {
        setUser_ID(ID);
        setTargetUser(FullName);
        setDetailShow(true);
    }

    const handleCloseDetail = () => {
        setDetailShow(false);
        setUser_ID('');
    }






    const renderLedger = () => {
        if (listOfLedger != null && listOfLedger.length > 0) {
            let count = 1;
            return listOfLedger.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{totalRecords - (count++ + pageSize * (pageNo - 1)) + 1}</td>
                        <td>{item.Full_Name}</td>
                        <td><Link to={`/admin/userstatistics-dashboard/leadership-view-team/${item.Nodes_IN_PK_Code}?Full_Name=${item.Full_Name}`}>{item.User_Name}</Link></td>
                        <td>{item.Email_Address}</td>
                        <td>
                            <div className="d-flex">
                                {item.Postal_Address}
                            </div>
                        </td>
                        <td>PKR {NumberFormat(item.Current_Balance)}</td>
                        <td>PKR {NumberFormat(item.Investment_Balance)}</td>
                        <td>
                            <div className="button-row justify-content-center flex-nowrap">
                                <Link to={`/admin/userstatistics-dashboard/leadership-view/${item.Nodes_IN_PK_Code}`} className="button small-button">
                                    View
                                </Link>
                                <Link to={`/admin/userstatistics-dashboard/leadership-view-team/${item.Nodes_IN_PK_Code}?Full_Name=${item.Full_Name}`} className="button small-button" >
                                    View Team
                                </Link>
                                <button className="button small-button" onClick={(ID, FullName) => handleDetail(item.Nodes_IN_PK_Code, item.Full_Name)}>
                                    Set Target
                                </button>
                            </div>
                        </td>
                    </tr >
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="16">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsUpdated(true);
    };
    const handleTarget = (event) => {
        setTargetLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            TargetAmount: Amount,
            startDate: TargetStartDate,
            endDate: TargetEndDate,
            CommissionRate: CommissionRate,
            MemberID: User_ID,
        };

        // console.log("data", data);
        var api_config = {
            method: "post",
            url: config.base_url + "LeaderCommission/AddLeaderTarget",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status_code == 1) {
                    toast.success(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setTargetLoading(false);
                    handleNull();
                    handleCloseDetail();
                } else if (
                    (response.data.status_code === 0 &&
                        response.data.status_message === "Invalid Access Key.") ||
                    response.data.status_code === 2
                ) {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.data.status_message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    };
    const handleNull = () => {
        setTargetStartDate('');
        setTargetEndDate('');
        setAmount('');
        setCommissionRate('');
        setUser_ID('');
        setTargetUser('');
    }

    return (
        <>
            <ToastContainer />
            <AdminRightsCheck />
            <Modal
                className="admin-support-modal modal-theme  p-0"
                show={showModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Leader Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-start">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <AdminCard
                                link="/admin/support-dashboard/new-ticket"
                                class="brown"
                                // value={NewTickets}
                                label="Self Investment"
                                icon="add_reaction"
                                subheading="Members"
                                no="(50)"
                            />
                        </div>
                        <div className="col-md-3">
                            <AdminCard
                                link="/admin/support-dashboard/open-ticket"
                                class="green"
                                // value={Open}
                                label="Team Investment"
                                icon="open_in_new"
                                subheading="Members"
                                no="(50)"
                            />
                        </div>
                        <div className="col-md-3">
                            <AdminCard
                                link="#/"
                                class="blue"
                                // value={Pending}
                                label="Target"
                                icon="hdr_strong"
                                subheading="Members"
                                no="(50)"
                            />
                        </div>
                        <div className="col-md-3">
                            <AdminCard
                                link="#/"
                                class="red"
                                // value={Close}
                                label="Achieved Target"
                                icon="gpp_bad"
                                subheading="Members"
                                no="(50)"
                            />
                        </div>
                        <div className="col-md-3">
                            <AdminCard
                                link="#/"
                                class="red"
                                // value={Close}
                                label="Remaining Target"
                                icon="gpp_bad"
                                subheading="Members"
                                no="(50)"
                            />
                        </div>
                        <div className="col-md-3">
                            <AdminCard
                                link="#/"
                                class="red"
                                // value={Close}
                                label="Paid Commission"
                                icon="gpp_bad"
                                subheading="Members"
                                no="(50)"
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="bs-modal"
                show={DetailShow}
                onHide={handleCloseDetail}
                onExit={handleNull}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set Target From {TargetUser}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="icon-form-group">
                        <label htmlFor="">From</label>
                        <div className="icon-input">
                            <input
                                type="date"
                                className="form-control"
                                value={TargetStartDate}
                                onChange={(e) => setTargetStartDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="icon-form-group">
                        <label htmlFor="">To</label>
                        <div className="icon-input">
                            <input
                                type="date"
                                className="form-control"
                                value={TargetEndDate}
                                onChange={(e) => setTargetEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="icon-form-group">
                        <label htmlFor="">Target Amount</label>
                        <div className="icon-input">
                            <input type="text" placeholder='Target Amount' value={Amount} onChange={(e) => setAmount(e.target.value)} />
                            <div className="icon">
                                <BanknotesIcon />
                            </div>
                        </div>
                    </div>
                    <div className="icon-form-group">
                        <label htmlFor="">Commission %</label>
                        <div className="icon-input">
                            <input type="text" placeholder='Commission %' value={CommissionRate} onChange={(e) => setCommissionRate(e.target.value)} />
                            <div className="icon">
                                <b>%</b>
                            </div>
                        </div>
                    </div>
                    <button className="button button-primary w-100" disabled={TargetLoading} type='submit' onClick={handleTarget}>
                        Save Target {TargetLoading ? '...' : ''}
                    </button>
                </Modal.Body>
            </Modal>
            <ReactTooltip />
            <div className="admin-content">
                <div className="row align-items-center gy-3">
                    <div className="col-md-6">
                        <div className="d-title m-0">
                            Leadership Commission
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <div className="">
                            <NavLink to="/admin/userstatistics-dashboard">
                                <button type="button" className="button button-outline-bs">
                                    Back
                                </button>
                            </NavLink>
                            <button
                                onClick={() => {
                                    setShowFilters((prevIsActive) => !prevIsActive);
                                }}
                                className="button button-primary ms-3"
                                style={{ fontWeight: "500", display: "inline-flex", alignItems: "center" }}
                            >
                                {showFilters ? "Hide Filters" : "Show Filters"}
                                <i className="ri-filter-3-line ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`collapse ${showFilters ? "show" : ""} `}
                    id="collapseFilteres"
                >
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-3 gy-3">
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">From</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={StartDate}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form_default">
                                            <div className="form-group search_box">
                                                <label htmlFor="">To</label>
                                                <div className="inputwrap">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={EndDate}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="">Designation</label>
                                            <div className="inputwrap">
                                                <select
                                                    id="dropdown"
                                                    className="form-select"
                                                    value={Designation}
                                                    onChange={(e) =>
                                                        setDesignation(e.target.value)
                                                    }
                                                >
                                                    <option value="" disabled>All</option>
                                                    <option value="Regional Sales Manager">Regional Sales Manager</option>
                                                    <option value="Sales Director">Sales Director</option>
                                                    <option value="Managing Director">Managing Director</option>
                                                    <option value="Country Head">Country Head</option>
                                                    <option value="District Sales Manager">District Sales Manager</option>
                                                    <option value="Sales Manager">Sales Manager</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <ul className="downloads">
                                            <li className="round-button" data-tip={'Download as Pdf'} data-place="bottom">
                                                <Link target="_blank" to={`/admin/userstatistics-dashboard/leadership/pdf?StartDate=${TargetStartDate}&EndDate=${TargetEndDate}&Full_Name=${Full_Name}`}>PDF</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form_default position-relative">
                                            <Form onSubmit={handleSearch}>
                                                <div className="row align-items-end gy-3 gx-1">
                                                    <div className="col-md-7">
                                                        <div className="form-group search_box">
                                                            <label htmlFor="">Search Member</label>
                                                            <div className="inputwrap">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Find by Username/Member ID/Email"
                                                                    value={Full_Name}
                                                                    onChange={(e) => setFull_Name(e.target.value)}
                                                                />
                                                                <span className="material-symbols-outlined">search</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <button type="submit" className="button w-100 button-primary input-height input-radius">
                                                            search
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card card-table mb-5 mt-3">
                    <div className="card-inner responsive-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Member Name</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Designation</th>
                                    <th>Cash Balance</th>
                                    <th>Total Packages</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td className="text-center" colSpan="16">
                                            <Spinner animation="border" role="status"></Spinner>
                                        </td>
                                    </tr>
                                ) : (
                                    <>{renderLedger()}
                                        {
                                            listOfLedger.length > 0 &&
                                            <>
                                                <tr style={{ borderButtom: "none !important" }}>
                                                    <th colSpan={4} className="border-bottom-0 pt-3"></th>
                                                    <th className="text-center border-bottom-0 pt-3">Subtotal:</th>
                                                    <th className="border-bottom-0 pt-3">PKR {NumberFormat(calculateColumnTotal(listOfLedger, 'Current_Balance'))}</th>
                                                    <td className="border-bottom-0 pt-3"></td>

                                                </tr>
                                                <tr className="">
                                                    <th className="pt-3" colSpan={4}></th>
                                                    <th className="text-center">Grand Total:</th>
                                                    <th>PKR {NumberFormat(GrandTotal.totalBalance)}</th>

                                                    <td style={{ borderButtom: "1px solid !important" }}></td>

                                                </tr>
                                            </>
                                        }
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                {totalRecords > 10 && (
                    <div className="pagination-wrap">
                        <div className="row align-items-center">
                            <div className="col-md-2 form_default">
                                <div className="d-flex align-items-center">
                                    <label className="me-2" style={{ fontSize: "15px" }}>
                                        Page size
                                    </label>
                                    <div className="form-group">
                                        <select
                                            className="form-select"
                                            style={{ width: "100px" }}
                                            onChange={(e) => {
                                                setPageNo(1);
                                                setPageSize(parseInt(e.target.value));
                                            }}
                                        >
                                            <option value="10" selected>
                                                10
                                            </option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="500">500</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Pagination
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
export default Leadership